<template>
  <div class="d-flex w-100 h-100 p-3 flex-column">
    <vue-cookie-accept-decline class="reverseColor"
    :ref="'myPanel1'"
    :elementId="'myPanel1'"
    :debug="false"
    :position="'bottom-left'"
    :type="'floating'"
    :disableDecline="false"
    :transitionName="'slideFromTop'"
    :showPostponeButton="false"
    @status="cookieStatus"
    @clicked-accept="cookieClickedAccept"
    @clicked-decline="cookieClickedDecline">

    <div slot="message">
        <p>Certains de nos partenaires utilisent des cookies, afin de pouvoir accéder l'intégralité des services, vous devez consentir à leur exploitation.</p>
        <router-link to="/legal-notice">En savoir plus...</router-link>
    </div>

    <div slot="declineContent">
       Je refuse...
    </div>

    <div slot="acceptContent">
        J'accepte !
    </div>
</vue-cookie-accept-decline>
    <Header />
    <router-view @removeCookie="removeCookie"></router-view>
    <Footer />
  </div>
</template>


<script>
import Header from "/src/components/Header.vue";
import Footer from "/src/components/Footer.vue";

 
export default {
  name: "App",
  data () {
    return {
        status: null
    }
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    cookieStatus (status) {
      this.status = status
    },
    cookieClickedAccept () {
      this.status = 'accept'
    },
    cookieClickedDecline () {
      this.status = 'decline'
    },
    cookieRemovedCookie () {
      this.status = null
      this.$refs.myPanel1.init()
    },
    removeCookie () {
      this.$refs.myPanel1.removeCookie()
      this.cookieRemovedCookie()
    }
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Accueil",
    // all titles will be injected into this template
    titleTemplate: "%s | LEFLOCH Métal Concept",
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no",
      },
      {
        name: "description",
        content:
          "Entreprise spécialisée dans la conception et l'installation de menuiseries Aluminium, Acier et PVC ainsi que dans l'intégration domotique",
      },
      {
        property: "og:description",
        content:
          "Entreprise spécialisée dans la conception et l'installation de menuiseries Aluminium, Acier et PVC ainsi que dans l'intégration domotique",
      },
      {
        property: "og:site-name",
        content: "LEFLOCH Métal Concept",
      },
      {
        property: "og:image",
        content: "/images/LOGO_ombre.png",
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "twitter:description",
        content:
          "Entreprise spécialisée dans la conception et l'installation de menuiseries Aluminium, Acier et PVC ainsi que dans l'intégration domotique",
      },
      {
        property: "twitter:title",
        content: "LEFLOCH Métal Concept",
      },
      {
        property: "twitter:image",
        content: "/images/LOGO_ombre.png",
      },
      {
        property: "twitter:card",
        content: "summary_large_image",
      },
    ],
  },
};
</script>

<style lang="scss">
@viewport {
  width: device-width;
}

@font-face {
  font-family: "oswald-r";
  src: url("/assets/Oswald-Regular.ttf") format("truetype");
}

.usefont {
  font-family: "oswald-r", serif;
}

html,
body {
  height: 100% !important;
  text-align: center;
  background-color: #333 !important;
  overflow: auto;
}
body {
  display: -ms-flexbox !important;
  display: flex !important;
  color: #fff !important;
  text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
}

.reverseColor  {
  color: #000000 !important;
  text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
  a {
  color: #000000 !important;
  }
}

.cover-container {
  max-width: 65em;
  align-self: center;
}

h3 {
  font-size: 1.75rem !important;
}

.cover {
  padding: 0 1.5rem;
}

.cover .btn-lg {
  padding: 0.75rem 1.25rem;
  font-weight: 700;
}

.btn-secondary:not(:hover):not(:focus) {
  color: #333 !important;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #fff !important;
  border: 0.05rem solid #fff !important;
}


</style>
