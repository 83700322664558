import Vue from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { faEnvelope, faAddressCard, faLightbulb, faMapMarkedAlt, faStore, faPhoneAlt, faBook } from "@fortawesome/free-solid-svg-icons"
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import router from './router'

library.add([faInstagram, faFacebookF, faEnvelope, faAddressCard, faMapMarkedAlt, faLightbulb, faStore, faPhoneAlt, faBook]);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}
).$mount('#app')
