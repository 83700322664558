<template>
  <nav class="nav nav-masthead justify-content-center">
    <router-link class="nav-link" to="/" exact>Accueil</router-link>
    <router-link class="nav-link" to="/services">Nos services</router-link>
    <router-link class="nav-link" to="/gallery">Galerie</router-link>
    <router-link class="nav-link" to="/configurator">Configurateur</router-link>
    <router-link class="nav-link" to="/about">À propos</router-link>
  </nav>
</template>
<script>
export default {
  name: "myNavbar",
};
</script>
<style>
a,
a:focus,
a:hover {
  color: #fff !important;
}

.nav-masthead {
  padding-bottom: min(15px, 5%);
}

.nav-masthead .nav-link {
  padding: 0.25rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border-bottom: 0.25rem solid transparent;
}

.nav-masthead .nav-link:hover {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .router-link-active {
  color: #fff;
  border-bottom-color: #fff;
}
</style>