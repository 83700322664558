import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("/src/views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("/src/views/About.vue"),
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: () => import("/src/views/Gallery.vue"),
  },
  {
    path: "/configurator",
    name: "Configurator",
    component: () => import("/src/views/Configurator.vue"),
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("/src/views/Services.vue"),
  },
  {
    path: "/legal-notice",
    name: "LegalNotice",
    component: () => import("/src/views/LegalNotice.vue"),
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
