<template>
  <header class="masthead mx-auto mb-auto header-container">
    <img
      :src="require('/public/images/LOGO_ombre.png')"
      class="img-fluid float-left p-0 small-img"
      alt="logolmc"
    />
    <div class="inner">
      <h3 class="masthead-brand usefont p-3">LEFLOCH Métal Concept</h3>
      <MyNavbar/>
    </div>
  </header>
</template>
<script>
import MyNavbar from '/src/components/myNavbar.vue';
export default {
  name: "Header",
  components: {
    MyNavbar,
  },
};
</script>
<style lang="scss">
.masthead {
  margin-bottom: 1.5rem;
}
.float-left {
  float: left;
}

.small-img {
  width: 100px;
}

.header-container {
  max-width: 42em;
}

.masthead-brand {
  margin-bottom: 0;
}

@media (min-width: 48em) {
  .masthead-brand {
    float: left;
  }
  .nav-masthead {
    float: right;
  }
}

@media (max-width: 420px) {
  .float-left {
    float:none;
  }
}
</style>