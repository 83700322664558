<template lang="html">
  <footer class="mastfoot mt-auto mx-auto">
    <div class="mysocial">
      <div><a href="https://www.facebook.com/Lefloch-M%C3%A9tal-Concept-107179614889507">
          <p><font-awesome-icon :icon="['fab', 'facebook-f']" size="2x"> </font-awesome-icon>
          </p>
        </a></div>
      <div><a href="https://instagram.com/lefloch_metal_concept">
          <p><font-awesome-icon :icon="['fab', 'instagram']" size="2x"></font-awesome-icon></p>
        </a></div>
      <div><a href="mailto:contact@lefloch-metalconcept.fr">
          <p><font-awesome-icon :icon="['fas', 'envelope']" size="2x"></font-awesome-icon>
          </p>
        </a></div>
      <div><a href="tel:+33385811454">
          <p><font-awesome-icon :icon="['fas', 'phone-alt']" size="2x"></font-awesome-icon>
          </p>
        </a></div>
    </div>
    <div class="inner">
      &copy; LEFLOCH Métal Concept {{ currentYear }} | <router-link to="/legal-notice" class="clearlink ">Mentions
        légales & cookies
      </router-link>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
<style lang="css">
.mysocial {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mysocial p {
  text-decoration: none;
  color: #fff;
}

svg:hover:not(.clear) {
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.7));
}

.mastfoot {
  color: rgba(255, 255, 255, 0.5);
}

.clearlink {
  color: rgba(255, 255, 255, 0.5) !important;
  text-decoration: none;
  :hover {
    color: rgba(255, 255, 255, 0.55) !important;
  }
}
</style>